<script setup>
import CentsEn from '@/../img/thanks-message/25cents-en.svg';
import CentsNl from '@/../img/thanks-message/25cents-nl.svg';
import Button from '@/Components/Button/Button.vue';
import Icon from '@/Components/Icon.vue';
import { useGtm } from '@gtm-support/vue-gtm';
import copy from '@i/copy.svg';
import explodedHead from '@i/exploding-head.png';
import { usePage } from '@inertiajs/vue3';
import { computed, onMounted } from 'vue';
import MoneyBar from './MoneyBar.vue';
import Subtitle from './Subtitle.vue';
import Title from './Title.vue';

const props = defineProps({
    tag: String,
    wage: String,
});

const affiliateCode = computed(() => usePage().props.session?.affiliate_code);

const canShare = typeof navigator?.share !== 'undefined';
const gtm = useGtm();

const startShare = () => {
    if (navigator.share) {
        if (typeof gtm !== 'undefined') {
            gtm.trackEvent({
                event: 'employee_lead',
                affiliate_shared: true,
            });
        }

        navigator
            .share({
                title: 'Flexable',
                url: 'https://flexable.nl/c/' + affiliateCode.value,
            })
            .then(() => {})
            .catch(console.error);
    } else {
    }
};

const copyToClip = () => {
    if (typeof gtm !== 'undefined') {
        gtm.trackEvent({
            event: 'employee_lead',
            affiliate_shared: true,
        });
    }
    navigator.clipboard.writeText(`flexable.nl/c/${affiliateCode.value}`);
};

const copyAnimation = () => {
    const copyDiv = document.querySelector('.copy-btn:not(.animate-copy)');
    if (copyDiv) {
        copyDiv.classList.add('animate-copy');
        copyDiv.addEventListener('animationend', () => copyDiv.classList.remove('animate-copy'));
    }
};

onMounted(() => {
    gtm.trackEvent({
        event: 'employee_lead_registrations',
    });
});
</script>

<template>
    <Title class="mb-8" :text="$t('<strong>Thanks</strong> for<br> signing up!')" />

    <div v-if="props.tag === 'calculator'">
        <Subtitle class="relative">
            {{ $t('We will keep you informed about the latest developments of Flex@ble!') }}
        </Subtitle>
        <MoneyBar v-if="props.tag === 'calculator'" :wage="wage" />
        <div class="my-4">{{ $t('Average hourly wage at Flex@ble based on the entered age.') }}</div>
    </div>
    <Subtitle class="relative" v-else>
        {{ $t('We will keep you informed about the latest developments of Flex@ble!') }}
    </Subtitle>

    <div class="relative px-4 py-8 mt-12 bg-white lg:px-10 lg:py-12 text-blue rounded-8">
        <h3 class="relative leading-none uppercase text-pink text-7 lg:text-12">
            <!-- <img class="absolute top-0 -translate-y-1/2 right-3" :src="ThanksPeople" alt="" /> -->
            <span v-html="$t('<strong>Earn money</strong><br />while your friends<br /><strong>work?</strong>')"></span>

            <img class="inline h-7 lg:h-10" :src="explodedHead" alt="Mind blown" />
        </h3>
        <Subtitle class="relative mr-28 lg:mr-40">
            {{
                $t(
                    'Sign up now, share Flex@ble with your friends and always get €0.50 per hour when they work. In addition, stay informed about the latest developments of Flex@ble!'
                )
            }}
        </Subtitle>

        <Icon class="absolute bottom-5 right-5 lg:w-auto w-25" :icon="CentsEn" :iconDutch="CentsNl" alt="rewards" />
    </div>
    <div class="flex flex-col gap-2 mt-4 lg:gap-4 lg:flex-row">
        <button
            @click="copyAnimation(), copyToClip()"
            :class="{ 'animate-copy': isActive }"
            class="relative flex-1 py-2 font-bold text-left bg-white rounded-full lg:text-center copy-btn px-7 text-blue min-h-10 lg:min-h-15"
        >
            flexable.nl/c/{{ affiliateCode }}
            <Icon class="absolute -translate-y-1/2 top-1/2 right-7" :icon="copy" />
        </button>
        <button
            @click="startShare"
            v-if="canShare"
            class="flex-1 font-bold text-center text-white rounded-full bg-pink min-h-10 lg:min-h-15"
        >
            {{ $t('Share with your friends') }}
        </button>
    </div>
</template>
